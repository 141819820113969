import React, {useEffect, useState} from "react";
import {Person} from "../person";
import {Alert, Box, IconButton, Paper, Table, TableBody, TableContainer} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from 'react-router-dom';
import {PersonTableHead} from "./PersonTableHead";
import {PersonTableRow} from "./PersonTableRow";
import {PersonDetailTableRow} from "./PersonDetailTableRow";
import {deletePerson, getPersons} from "../personService";
import {useAuth} from "react-oidc-context";
import {userIsAdmin} from "../../security/entranceUser";

function PersonList() {
    const auth = useAuth();
    const accessToken = auth.user?.access_token ?? '';
    const isAdmin = userIsAdmin(auth.user)

    const [persons, setPersons] = useState<Person[]>([]);
    const [selectedPerson, setSelectedPerson] = useState<Person | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        getPersons(accessToken)
            .then(persons => setPersons(persons))
            .catch(error => setError(new Error("Could not fetch persons", {cause: error})));
    }, [accessToken])

    const handleRowClick = (clickedPerson: Person) => {
        if (selectedPerson?.id === clickedPerson.id) {
            setSelectedPerson(undefined);
            return;
        }
        setSelectedPerson(clickedPerson);
    }

    const handleEditClick = (personId: number | undefined) => {
        if (personId === undefined) {
            return;
        }

        navigate(`/edit-person/${personId}`); // Navigate to the edit person route
    };

    const handleDeleteClick = async (personId: number | undefined) => {
        if (personId === undefined) {
            return;
        }

        // Delete the person
        try {
            await deletePerson(personId, accessToken)
            setPersons(persons.filter(person => person.id !== personId))
        } catch (error) {
            setError(new Error("Could not delete person", {cause: error}));
        }
    };

    if (error) {
        return (
            <Box sx={{display: 'flex'}}>
                <Alert severity="error">{error.message}</Alert>
            </Box>
        );
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <PersonTableHead showDelete={isAdmin}/>
                    <TableBody>
                        {persons.map((person) => (
                            <>
                                {/*This is a main content row containing person details and action button*/}
                                <PersonTableRow person={person}
                                                showDelete={isAdmin}
                                                onClick={handleRowClick}
                                                onEditClick={handleEditClick}
                                                onDeleteClick={handleDeleteClick}
                                />
                                {/*This is a "hidden" row containing card readers that will be shown on click*/}
                                <PersonDetailTableRow show={selectedPerson?.id === person.id} person={person}/>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {isAdmin ? <IconButton color='primary' size="large"
                                   onClick={() => navigate('/add-person')}><AddIcon/></IconButton> : null}
        </>
    );
}

export default PersonList;