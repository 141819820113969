import {CardReader} from "../cardReaders/cardReader";

export type Person = {
    id?: number;
    name: string;
    email: string;
    active: boolean;
    gender?: Gender;
    cardReaders: CardReader[];

    password?: string;
}

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER',
    MAIA = 'MAIA'
}