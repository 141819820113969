import {User} from "oidc-client-ts";
import {jwtDecode} from "jwt-decode";

export function userIsAdmin(user: User | null | undefined): boolean {
    if (!user) {
        return false
    }

    const access_token = jwtDecode<any>(user.access_token)
    const realmAccess = access_token["realm_access"]
    if (!realmAccess) {
        return false
    }
    const roles = realmAccess.roles as string[]
    if (!roles) {
        return false
    }

    return roles.includes("admin")
}