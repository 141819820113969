import {Person} from "./person";
import {restURI} from "../config";

export async function createPerson(person: Person, accessToken: string) {
    const response = await fetch(`${restURI}/persons`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(person)
    })
    const data = await response.json();
    return data as Person;
}

export async function deletePerson(personId: number, accessToken: string) {
    await fetch(`${restURI}/persons/${personId}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
    })
}

export async function getPerson(personId: number, accessToken: string) {
    const response = await fetch(`${restURI}/persons/${personId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    const data = await response.json();
    return data as Person;
}

export async function getPersons(accessToken: string) {
    const response = await fetch(`${restURI}/persons`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    const data = await response.json();
    return data.content as Person[];
}

export async function updatePerson(person: Person, accessToken: string) {
    const response = await fetch(`http://localhost:8080/persons`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(person)
    })

    const data = await response.json();
    return data as Person;
}
