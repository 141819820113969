import {restURI} from "../config";
import {CardReader} from "./cardReader";

export async function getCardReaders(accessToken: string) {
    let response = await fetch(`${restURI}/card-readers`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    let data = await response.json();
    return data as CardReader[];
}