import {Button, IconButton, TableCell, TableRow} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {Person} from "../person";

type Props = {
    person: Person,
    showDelete: boolean,
    onClick: (clickedPerson: Person) => void,
    onEditClick: (personId: (number | undefined)) => void,
    onDeleteClick: (personId: (number | undefined)) => void
}

export function PersonTableRow(props: Props) {
    const {person, showDelete, onClick, onEditClick, onDeleteClick} = props;

    return <TableRow onClick={() => onClick(person)}>
        <TableCell>{person.id}</TableCell>
        <TableCell>{person.name}</TableCell>
        <TableCell>{person.active ? 'Yes' : 'No'}</TableCell>
        <TableCell>{person.gender}</TableCell>
        <TableCell>{person.cardReaders ? person.cardReaders.length : 0}</TableCell>
        <TableCell>
            <Button onClick={() => onEditClick(person.id)}>Edit</Button>
        </TableCell>
        {showDelete ? <TableCell>
            <IconButton onClick={() => onDeleteClick(person.id)}
                        color='warning'><DeleteIcon/></IconButton>
        </TableCell> : null}

    </TableRow>;
}

