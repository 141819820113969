import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    TextField,
    Button,
    Checkbox,
    Typography,
    Box,
    Alert,
    ButtonGroup, Divider, Stack, FormControlLabel, Switch, Select, MenuItem
} from '@mui/material';
import {Gender, Person} from "./person";
import CheckIcon from '@mui/icons-material/Check';
import {getCardReaders} from "../cardReaders/cardReaderService";
import {createPerson} from "./personService";
import {CardReader} from "../cardReaders/cardReader";
import {useAuth} from "react-oidc-context";


export function PersonAdd() {
    const auth = useAuth();
    const accessToken = auth.user?.access_token ?? ''

    const [person, setPerson] = useState<Person>({name: '', email: '', active: false, cardReaders: []});
    const [allCardReaders, setAllCardReaders] = useState<CardReader[]>([]);
    const [error, setError] = useState<Error | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        getCardReaders(accessToken)
            .then(cardReaders => setAllCardReaders(cardReaders))
            .catch(error => setError(new Error("Could not fetch card readers", {cause: error})));
    }, [accessToken]);

    if (error) {
        return (
            <Box sx={{display: 'flex'}}>
                <Alert severity="error">{error.message}</Alert>
            </Box>
        );
    }

    async function handleSubmit() {
        await createPerson(person, accessToken)
            .catch(error => setError(new Error("Could not save person", {cause: error})));
        navigate('/');
    }

    function isPersonNameValid(): boolean {
        return person.name.length > 0;
    }

    function isPersonEmailValid(): boolean {
        const email = person.email;
        if (email.length === 0) {
            return false
        }

        if (!email.includes('@')) {
            return false
        }

        const atIdx = email.indexOf('@')
        if (!email.substring(atIdx).includes('.')) {
            return false
        }

        return true
    }

    return (
        <Stack spacing={2}>
            <Divider component="div" role="presentation">
                <Typography variant="h4">
                    Add Person
                </Typography>
            </Divider>

            <TextField
                fullWidth
                margin="normal"
                label="Name"
                name="name"
                value={person.name}
                error={!isPersonNameValid()}
                helperText={!isPersonNameValid() ? "Name must not be empty" : ""}
                onChange={(e) => {
                    setPerson((currPerson) => ({...currPerson, name: e.target.value} as Person));
                }}
            />

            <TextField
                fullWidth
                margin="normal"
                label="Email"
                name="email"
                value={person.email}
                error={!isPersonEmailValid()}
                helperText={!isPersonEmailValid() ? "Invalid email" : ""}
                onChange={(e) => {
                    setPerson((currPerson) => ({...currPerson, email: e.target.value} as Person));
                }}
            />

            <TextField
                fullWidth
                margin="normal"
                label="Password"
                name="password"
                value={person.password}
                type="password"
                onChange={(e) => {
                    setPerson((currPerson) => ({...currPerson, password: e.target.value} as Person));
                }}
            />

            <FormControlLabel control={
                <Switch
                    checked={person.active}
                    name="active"
                    onChange={(e) => {
                        setPerson((currPerson) => ({...currPerson, active: e.target.checked} as Person));
                    }}
                />} label="Active"/>

            <Select
                value={person.gender}
                label="Gender"
                onChange={(e) => {
                    setPerson((currPerson) => ({...currPerson, gender: e.target.value} as Person));
                }}
            >
                {Object.values(Gender).map((value) => (
                    <MenuItem key={value} value={value}>
                        {value}
                    </MenuItem>
                ))}
            </Select>

            <Divider component="div" role="presentation" textAlign="left">
                <Typography variant="h5">Card readers</Typography>
            </Divider>

            {allCardReaders.map((cardReader) => (
                <FormControlLabel key={cardReader.id} control={
                    <Checkbox
                        checked={person.cardReaders.map((cr) => cr.id).includes(cardReader.id)}
                        name={cardReader.position}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setPerson((currPerson) => ({
                                    ...currPerson,
                                    cardReaders: [...currPerson.cardReaders, cardReader]
                                } as Person));
                            } else {
                                setPerson((currPerson) => ({
                                    ...currPerson,
                                    cardReaders: currPerson.cardReaders.filter((cr) => cr.id !== cardReader.id)
                                } as Person));
                            }
                        }}
                    />} label={cardReader.position}/>
            ))}


            <ButtonGroup>
                <Button disabled={!isPersonNameValid()} endIcon={<CheckIcon/>} variant="contained" color="primary" onClick={handleSubmit}>
                    Save
                </Button>
                <Button variant="contained" color="inherit">
                    Cancel
                </Button>
            </ButtonGroup>
        </Stack>
    );
}