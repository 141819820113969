import {TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";


export function PersonTableHead(props: { showDelete: boolean }) {
    return <TableHead>
        <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Gender</TableCell>
            <TableCell># Card Readers</TableCell>
            <TableCell>{/*Edit*/}</TableCell>
            {props.showDelete ? <TableCell>{/*Delete*/}</TableCell> : null }
        </TableRow>
    </TableHead>;
}