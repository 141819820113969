import React, {useMemo} from 'react';
import './App.css';
import PersonList from "./persons/personList/PersonList";
import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";
import {PersonEdit} from "./persons/PersonEdit";
import {PersonAdd} from "./persons/PersonAdd";
import {createTheme, CssBaseline, Theme, ThemeProvider, useMediaQuery} from "@mui/material";
import {useAuth} from "react-oidc-context";
import MyAppBar from "./MyAppBar";

function App() {
    // check if the user prefers dark mode by enabling it in the OS.
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = useMemo<Theme>(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );

    const auth = useAuth();

    if (auth.isLoading) {
        return <div>Signing in progress...</div>;
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <MyAppBar/>
            {auth.isAuthenticated &&
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<PersonList/>}/>
                        <Route path="/edit-person/:id" element={<PersonEdit/>}/>
                        <Route path="/add-person" element={<PersonAdd/>}/>
                    </Routes>
                </BrowserRouter>
            }
        </ThemeProvider>
    );
}

export default App;
