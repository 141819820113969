import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    TextField,
    Button,
    Checkbox,
    Typography,
    Box,
    Alert,
    CircularProgress,
    ButtonGroup, Divider, Stack, FormControlLabel, Switch
} from '@mui/material';
import {Person} from "./person";
import CheckIcon from '@mui/icons-material/Check';
import {getPerson, updatePerson} from "./personService";
import {getCardReaders} from "../cardReaders/cardReaderService";
import {CardReader} from "../cardReaders/cardReader";
import {useAuth} from "react-oidc-context";
import {userIsAdmin} from "../security/entranceUser";


export function PersonEdit() {
    const auth = useAuth();
    const accessToken = auth.user?.access_token ?? ''

    const {id} = useParams<{ id: string }>();
    const [person, setPerson] = useState<Person>({
        name: '',
        email: '',
        active: false,
        gender: undefined,
        cardReaders: []
    });
    const [allCardReaders, setAllCardReaders] = useState<CardReader[] | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (id === undefined) {
            return;
        }

        getPerson(parseInt(id), accessToken)
            .then(person => setPerson(person))
            .catch(error => setError(new Error("Could not fetch persons", {cause: error})));

        getCardReaders(accessToken)
            .then(cardReaders => setAllCardReaders(cardReaders))
            .catch(error => setError(new Error("Could not fetch card readers", {cause: error})));

    }, [id, accessToken]);

    if (error) {
        return (
            <Box sx={{display: 'flex'}}>
                <Alert severity="error">{error.message}</Alert>
            </Box>
        );
    }


    if (!id) {
        return (
            <Box sx={{display: 'flex'}}>
                <Alert severity="error">Person ID not set</Alert>
            </Box>
        );
    }

    if (!person.id) {
        return (
            <Box sx={{display: 'flex'}}>
                <CircularProgress/>
            </Box>
        );
    }

    async function handleSubmit() {
        try {
            await updatePerson(person, accessToken)
        } catch (error) {
            setError(new Error("Could not save person", {cause: error}))
        }
        navigate('/');
    }

    function isPersonNameValid(): boolean {
        return person.name.length > 0;
    }

    return (
        <Stack spacing={2}>
            <Divider component="div" role="presentation">
                <Typography variant="h4">
                    Edit Person
                </Typography>
            </Divider>


            <TextField
                fullWidth
                label="Name"
                value={person.name}
                error={!isPersonNameValid()}
                helperText={!isPersonNameValid() ? "Name must not be empty" : ""}
                onChange={(e) => {
                    setPerson({...person, name: e.target.value} as Person);
                }}
            />

            <TextField
                fullWidth
                label="Email"
                value={person.email}
                disabled={true}
            />

            <TextField
                fullWidth
                margin="normal"
                label="Password"
                name="password"
                value={person.password}
                type="password"
                onChange={(e) => {
                    setPerson((currPerson) => ({...currPerson, password: e.target.value} as Person));
                }}
            />

            <FormControlLabel control={
                <Switch
                    checked={person.active}
                    name="active"
                    disabled={!userIsAdmin(auth.user)}
                    onChange={(e) => {
                        setPerson({...person, active: e.target.checked} as Person);
                    }}
                />} label="Active"/>

            <Divider component="div" role="presentation" textAlign="left">
                <Typography variant="h5">Card readers</Typography>
            </Divider>

            {allCardReaders?.map((cardReader) => (
                <FormControlLabel key={cardReader.id} control={
                    <Checkbox
                        checked={person.cardReaders.map((cr) => cr.id).includes(cardReader.id)}
                        name={cardReader.position}
                        disabled={!userIsAdmin(auth.user)}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setPerson({
                                    ...person,
                                    cardReaders: [...person.cardReaders, cardReader]
                                } as Person);
                            } else {
                                setPerson({
                                    ...person,
                                    cardReaders: person.cardReaders.filter((cr) => cr.id !== cardReader.id)
                                } as Person);
                            }
                        }}
                    />} label={cardReader.position}/>
            ))}


            <ButtonGroup>
                <Button disabled={!isPersonNameValid()} endIcon={<CheckIcon/>} variant="contained" color="primary"
                        onClick={handleSubmit}>
                    Save
                </Button>
                <Button variant="contained" color="inherit" onClick={() => navigate('/')}>
                    Cancel
                </Button>
            </ButtonGroup>
        </Stack>
    );
}