import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {useAuth} from "react-oidc-context";

export default function MyAppBar() {
    const auth = useAuth();

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Entrance Admin Console
                    </Typography>
                    {auth.isAuthenticated ?
                        <Button color="inherit" onClick={() => {
                            // log out from this app
                            void auth.removeUser()
                            // log out from keycloak
                            void auth.signoutRedirect()
                        }}>Logout</Button>
                        : <Button color="inherit" onClick={() => void auth.signinRedirect()}>Login</Button> }
                </Toolbar>
            </AppBar>
        </Box>
    );
}
