import {Box, Collapse, TableCell, TableRow} from "@mui/material";
import React from "react";
import {Person} from "../person";

type Props = {
    show: boolean,
    person: Person
}

export function PersonDetailTableRow(props: Props) {
    const {show, person} = props;
    return <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
            <Collapse in={show} timeout="auto" unmountOnExit>
                <Box margin={1}>
                    <h6>Card Readers:</h6>
                    {person.cardReaders.map((reader) => (
                        <div key={reader.id}>{reader.position}</div>
                    ))}
                </Box>
            </Collapse>
        </TableCell>
    </TableRow>;
}