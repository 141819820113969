import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "react-oidc-context";
import {oidcAuthority, oidcClientId, oidcRedirectURI, oidcSecret} from "./config";
import {WebStorageStateStore} from "oidc-client-ts";
// import {WebStorageStateStore} from "oidc-client-ts";

const oidcConfig = {
    authority: oidcAuthority,
    client_id: oidcClientId,
    client_secret: oidcSecret,
    redirect_uri: oidcRedirectURI,
    personStore: new WebStorageStateStore({ store: window.localStorage }),
};

const onSigninCallback = (): void => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    )
}


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
        <App/>
    </AuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
